import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { H, P, Grid, Image } from '@farewill/ui'
import { COLOR, FONT } from '@farewill/ui/tokens'
import { screen, screenMax } from '@farewill/ui/helpers/responsive'

import Specialists from 'components/Specialists'
import { AWARD_LOGO_URL, AWARD_STRAPLINE } from 'lib/awards/constants'
import { PROBATE } from 'lib/products/constants'
import WrapperWithTextures from '../WrapperWithTextures'

const StyledSpecialistsWrapper = styled(Grid.Item)`
  ${screen.m`
    align-self: end;
  `}
`

const StyledImageWrapper = styled(Grid.Item)`
  align-self: start;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 320px;

  ${screenMax.m`
    margin: 0 auto;
  `}
`

const StyledImage = styled(Image)`
  ${(props) => props.stretch && `height: 100%; max-height: ${props.height}px;`}
`

const StyledText = styled(P)`
  font-weight: ${FONT.WEIGHT.BOLD};

  ${screenMax.m`
    text-align: center;
  `}
`

interface AwardWinningProps {
  title: string
  subtitle: string
  awardDescription?: string
}

const AwardWinning = ({
  title,
  subtitle,
  awardDescription,
}: AwardWinningProps): React.ReactElement => (
  <WrapperWithTextures>
    <Grid.Item spanFromM={6} spanRows={2} spanRowsFromL={1}>
      <H tag="h2" size="L" decorative>
        {title}
      </H>
      <P color={COLOR.BLACK} maxWidth={520}>
        {subtitle}
      </P>
    </Grid.Item>

    <StyledSpecialistsWrapper spanFromM={6} centered>
      <Specialists group={PROBATE} max={4} />
    </StyledSpecialistsWrapper>

    <Grid.Item spanFromM={6}>
      <StyledText color={COLOR.BLACK}>{awardDescription}</StyledText>
      <StyledImageWrapper>
        <StyledImage
          path={AWARD_LOGO_URL.ACCOLADES_LAW_WILLS_PROBATE}
          width={405}
          height={73}
          alt={AWARD_STRAPLINE.ACCOLADES_LAW_WILLS_PROBATE}
          stretch
        />
      </StyledImageWrapper>
    </Grid.Item>
  </WrapperWithTextures>
)

AwardWinning.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  awardDescription: PropTypes.string,
}

AwardWinning.defaultProps = {
  awardDescription: AWARD_STRAPLINE.PROBATE_PROVIDER_OF_THE_YEAR,
}

export default AwardWinning
