import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Wrapper, H, P, Grid, Image } from '@farewill/ui'
import { FONT, COLOR } from '@farewill/ui/tokens'
import { getWidthOfContainerColumns } from '@farewill/ui/helpers/layout'
import { smallCaps } from '@farewill/ui/helpers/text'

import PreventOrphan from 'components/PreventOrphan'
import PATHS from 'paths'

const ARTICLES = [
  {
    illustration: 'key-unlocking-assets-no-yellow',
    name: 'What is probate?',
    description:
      'Probate (short for ‘a grant of probate’) is a legal document that shows banks, the Land Registry and other organisations that you have the authority to deal with someone’s estate. The process of getting probate usually takes 1-3 months.',
    article: 'what-is-probate',
  },
  {
    illustration: 'house-with-keyhole-no-yellow',
    name: 'When is probate required?',
    description:
      'Probate is usually required if the estate of the person who died is worth more than £10,000. However, if most of the assets in the estate were jointly owned, probate may not be needed at all.',
    article: 'when-is-probate-required',
  },
  {
    illustration: 'probate-process-no-yellow',
    name: 'How long does probate take in the UK?',
    description:
      'Probate usually takes 1-3 months depending on the complexity of the estate. It can then take up to 6 months to close accounts, sell property and pay taxes.',
    article: 'how-long-does-probate-take',
  },
]

const StyledHeading = styled(H)`
  font-size: ${FONT.SIZE.XL};
  font-weight: ${FONT.WEIGHT.MEDIUM};
`

const StyledLink = styled(Link)`
  ${smallCaps}
  text-decoration: none;
`
const Articles = () => (
  <Wrapper background={COLOR.BACKGROUND.FOG}>
    <Wrapper container>
      <H
        size="L"
        tag="h2"
        decorative
        centered
        margin={[0, 'S', 'M']}
        marginFromM={[0, 'auto', 'S']}
        maxWidth={getWidthOfContainerColumns(6, 'L')}
      >
        More questions about probate answered
      </H>

      <Grid
        gap="M"
        gapFromM="L"
        gapFromL="XL"
        gapFromXL="XXL"
        margin={[0, 0, 'L']}
        marginFromL={[0, 0, 'XXL']}
      >
        {ARTICLES.map(({ name, illustration, description, article }) => (
          <Grid.Item key={name} span={12} spanFromM={4}>
            <Wrapper
              centered
              bordered
              borderRadius="M"
              background={COLOR.WHITE}
              margin={[0, 0, 'M']}
              padding={['M']}
            >
              <Image
                path={`illustrations/square/${illustration}`}
                width={160}
                stretch
              />
            </Wrapper>
            <StyledHeading tag="h3">{name}</StyledHeading>
            <P>
              <PreventOrphan>{description}</PreventOrphan>
            </P>
            <StyledLink to={`${PATHS.GENERAL.ARTICLES}/${article}`}>
              Read more
            </StyledLink>
          </Grid.Item>
        ))}
      </Grid>
    </Wrapper>
  </Wrapper>
)

export default Articles
