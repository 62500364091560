import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Button, ArrowRightIcon } from '@farewill/ui'
import { FONT } from '@farewill/ui/tokens'
import { screenMin } from '@farewill/ui/helpers/responsive'
import ExternalLink from 'components/ExternalLink'
import { FLOW_PROBATE_URL } from 'config'

const MobileButton = styled(({ buttonType, ...rest }) => {
  const Component = Button[buttonType]
  return <Component {...rest} /> // eslint-disable-line react/jsx-props-no-spreading
})`
  font-weight: ${({ $boldText }) => $boldText && FONT.WEIGHT.BOLD};

  ${screenMin.m`
    display: none;
  `}
`

const DesktopButton = styled(({ buttonType, ...rest }) => {
  const Component = Button[buttonType]
  return <Component {...rest} /> // eslint-disable-line react/jsx-props-no-spreading
})`
  display: none;

  ${screenMin.m`
    display: inline-flex;
    font-weight: ${({ $boldText }) => $boldText && FONT.WEIGHT.BOLD};
  `}
`

const QuoteButton = ({
  buttonText,
  buttonType,
  boldText,
  showArrow,
  linkPath,
  isExternal,
}) => (
  <>
    <MobileButton
      stretch
      tag={isExternal ? ExternalLink : Link}
      to={linkPath}
      href={linkPath}
      icon={showArrow && <ArrowRightIcon size="M" />}
      $boldText={boldText}
      buttonType={buttonType}
    >
      {buttonText}
    </MobileButton>
    <DesktopButton
      tag={isExternal ? ExternalLink : Link}
      to={linkPath}
      href={linkPath}
      icon={showArrow && <ArrowRightIcon size="M" />}
      $boldText={boldText}
      buttonType={buttonType}
    >
      {buttonText}
    </DesktopButton>
  </>
)

QuoteButton.propTypes = {
  buttonText: PropTypes.string,
  buttonType: PropTypes.string,
  boldText: PropTypes.bool,
  showArrow: PropTypes.bool,
  linkPath: PropTypes.string,
  isExternal: PropTypes.bool,
}

QuoteButton.defaultProps = {
  buttonText: 'Get a personalised quote',
  buttonType: 'Primary',
  boldText: false,
  showArrow: true,
  linkPath: FLOW_PROBATE_URL,
  isExternal: false,
}

export default QuoteButton
