import React from 'react'

import { Grid, H, P } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

import { PricingCard } from 'components/PricingCards'
import BulletedList from 'components/BulletedList'
import QuoteButton from 'views/probate/components/QuoteButton'
import { FLOW_PROBATE_URL } from 'config'

import { ESTATE_ADMIN_PRICE } from './constants'

const Pricing = () => {
  const { price, title, included, notIncluded } = ESTATE_ADMIN_PRICE

  return (
    <Grid>
      <Grid.Item spanFromL="5">
        <H size="L" tag="h2" decorative>
          A fixed price with no deposit or hidden fees
        </H>
        <P>
          We will quote a fixed-price upfront depending on your situation (like
          if there’s inheritance tax to pay or lots of assets to manage).
        </P>
        <P margin={[0, 0, 'L']}>
          There’s no deposit upfront. And in most cases, you can pay out of the
          estate.
        </P>
        <QuoteButton
          buttonText="Get an instant quote"
          boldText
          showArrow={false}
          linkPath={FLOW_PROBATE_URL}
        />
      </Grid.Item>
      <Grid.Item spanFromL="6" startColumnFromL="7">
        <PricingCard key={title} price={price} title={title} highlight>
          <P size="S" margin={[0, 0, 'XS']} color={COLOR.GREY.DARK} strong>
            At the lower end if:
          </P>
          <BulletedList.Check items={included} />
          <P size="S" margin={[0, 0, 'XS']} color={COLOR.GREY.DARK} strong>
            What’s not included in our fixed fee:
          </P>
          <BulletedList.Plus items={notIncluded} />
        </PricingCard>
      </Grid.Item>
    </Grid>
  )
}

export default Pricing
