import React, { Key } from 'react'
import styled from 'styled-components'
import { COLOR, GTR } from '@farewill/ui/tokens'

import PreventOrphan from 'components/PreventOrphan'
import bulletPresets from './presets'

const StyledListItem = styled.li`
  display: block;
  position: relative;
  color: ${COLOR.GREY.DARK};
  padding-left: ${GTR.L};

  &:not(:last-of-type) {
    margin-bottom: ${GTR.XS};
  }
`

const StyledBullet = styled.div<{ bulletColor?: string }>`
  position: absolute;
  left: 0;
  top: 0;
  ${({ bulletColor }) => `color: ${bulletColor};`}
`

interface BulletedListProps {
  className?: string
  items: React.ReactNode[]
  bullet: React.ReactNode
  bulletColor?: string
}

interface IBulletedList<T> {
  (props: T): React.ReactElement
}

type BulletedListUsingPresetType = IBulletedList<
  Omit<BulletedListProps, 'bullet'>
>

interface IBulletPresetNamespace extends IBulletedList<BulletedListProps> {
  Check: BulletedListUsingPresetType
  CheckWithBackground: BulletedListUsingPresetType
  Cross: BulletedListUsingPresetType
  Plus: BulletedListUsingPresetType
}

const BulletedList = ({
  className,
  items,
  bullet,
  bulletColor,
}: BulletedListProps): React.ReactElement => (
  <ul className={className}>
    {items.map((item) => (
      <StyledListItem key={item as Key}>
        <StyledBullet bulletColor={bulletColor}>{bullet}</StyledBullet>
        <PreventOrphan>{item as any}</PreventOrphan>
      </StyledListItem>
    ))}
  </ul>
)

const BulletedListComponent = BulletedList as IBulletPresetNamespace

bulletPresets.forEach((preset) => {
  const BulletedListPreset = ({
    className,
    items,
    bulletColor,
  }: Omit<BulletedListProps, 'bullet'>): JSX.Element => (
    <BulletedList
      className={className}
      items={items}
      bullet={preset.bullet}
      bulletColor={bulletColor}
    />
  )

  BulletedListComponent[preset.name] = BulletedListPreset
})

export default BulletedListComponent
