import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { Image } from '@farewill/ui'
import { BREAKPOINT } from '@farewill/ui/tokens'

import BreakpointSwitch from 'components/BreakpointSwitch'
import { AWARD_LOGO_URL, AWARD_STRAPLINE } from 'lib/awards/constants'

const StyledImage = styled(Image)`
  ${(props) => props.stretch && `height: 100%; max-height: ${props.height}px;`}
`

const AccoladesImage = ({
  desktopUrl = AWARD_LOGO_URL.ACCOLADES_LAW_WILLS_PROBATE_WITH_SPACED_SHADOWS,
  mobileUrl = AWARD_LOGO_URL.ACCOLADES_LAW_WILLS_PROBATE,
}: {
  desktopUrl?: string
  mobileUrl?: string
}): ReactElement => (
  <BreakpointSwitch
    aboveComponent={
      <StyledImage
        path={desktopUrl}
        width={405}
        height={73}
        alt={AWARD_STRAPLINE.ACCOLADES_LAW_WILLS_PROBATE}
        stretch
      />
    }
    belowComponent={
      <StyledImage
        path={mobileUrl}
        width={308}
        height={60}
        alt={AWARD_STRAPLINE.ACCOLADES_LAW_WILLS_PROBATE}
        stretch
      />
    }
    breakAt={BREAKPOINT.S}
  />
)

export default AccoladesImage
