import React from 'react'
import styled from 'styled-components'
import { Wrapper, H, P, Grid, Image } from '@farewill/ui'
import { FONT, COLOR } from '@farewill/ui/tokens'

import PreventOrphan from 'components/PreventOrphan'

import { FEATURES } from './constants'

const StyledHeading = styled(H)`
  font-size: ${FONT.SIZE.L};
`
const Features = () => (
  <Wrapper background={COLOR.BACKGROUND.FOG}>
    <Wrapper container centered>
      <H
        size="L"
        tag="h2"
        decorative
        margin={[0, 'S', 'M']}
        marginFromM={[0, 'auto', 'S']}
      >
        How we can help
      </H>

      <Grid gap={0} gapFromM="L" gapFromL="XL" gapFromXL="XXL">
        {FEATURES.map(({ name, illustration, description }) => (
          <Grid.Item key={name} span={12} spanFromM={4}>
            <Image
              path={`illustrations/square/${illustration}`}
              width={200}
              stretch
            />
            <StyledHeading tag="h3">{name}</StyledHeading>
            <P>
              <PreventOrphan>{description}</PreventOrphan>
            </P>
          </Grid.Item>
        ))}
      </Grid>
    </Wrapper>
  </Wrapper>
)

export default Features
