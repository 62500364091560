import React from 'react'
import { Divider, Wrapper } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

import DefaultLayout from 'layouts/DefaultLayout'

import { PROBATE } from 'lib/products/constants'

import Hero from './Hero'
import AwardWinningBanner from '../../components/AwardWinningBanner'
import Pricing from './Pricing'
import Features from './Features'
import Quotes from './Quotes'
import Articles from '../../components/Articles'
import AboutUs from '../../components/AboutUs'
import HowItWorks from '../../components/HowItWorks'

const EstateAdministration = () => (
  <DefaultLayout
    title="Complete Probate Service | Probate"
    description="Our fixed-price Complete Probate service takes the stress of probate off your hands, giving you the time and space to focus on what really matters."
    product={PROBATE}
    headerColor={COLOR.WHITE}
    footerShowFwlsDisclaimer
  >
    <Wrapper background={COLOR.WHITE}>
      <Hero />

      <AwardWinningBanner
        title="Get specialist support from our award-winning team"
        subtitle="Our probate experts will manage all of the paperwork for you, keep you
        updated every step of the way, and give you the award-winning service
        we’re known for."
      />

      <Wrapper container>
        <HowItWorks />
      </Wrapper>

      <Divider container color={COLOR.BACKGROUND.FOG} />

      <Wrapper container>
        <Pricing />
      </Wrapper>

      <Features />

      <Quotes />

      <Articles />

      <AboutUs />
    </Wrapper>
  </DefaultLayout>
)

export default EstateAdministration
