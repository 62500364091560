import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Grid } from '@farewill/ui'
import { screenMax } from '@farewill/ui/helpers/responsive'

import Card from './Card'

const StyledPricingCards = styled(Grid)`
  ${({ $collapseColumnsOnM }) =>
    screenMax[$collapseColumnsOnM ? 'm' : 's']`
     grid-template-columns: 1fr;
     flex-direction: column;
   `}
`

const PricingCards = ({ children, collapseColumnsOnM }) => (
  <StyledPricingCards
    $collapseColumnsOnM={collapseColumnsOnM}
    columns={children.length}
    gapFromL="L"
    gapFromXL="XL"
  >
    {children}
  </StyledPricingCards>
)

PricingCards.propTypes = {
  children: PropTypes.node,
  collapseColumnsOnM: PropTypes.bool,
}

PricingCards.defaultProps = {
  children: undefined,
  collapseColumnsOnM: false,
}

export const PricingCard = Card

export default PricingCards
