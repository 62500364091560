import React from 'react'
import styled from 'styled-components'
import { PlusIcon, CrossIcon, TickIcon } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'
import { screenMax } from '@farewill/ui/helpers/responsive'

export const BulletPresetNames = {
  Check: 'Check',
  CheckWithBackground: 'CheckWithBackground',
  Cross: 'Cross',
  Plus: 'Plus',
} as const

export interface BulletPreset {
  name: keyof typeof BulletPresetNames
  bullet: JSX.Element
}

const StyledTickIcon = styled(TickIcon)`
  ${screenMax.m`
    margin-left: -8px;
    `}
`

const bulletPresets: readonly BulletPreset[] = [
  {
    name: BulletPresetNames.Check,
    bullet: <TickIcon inline size="S" />,
  },
  {
    name: BulletPresetNames.CheckWithBackground,
    bullet: <StyledTickIcon inline size="S" background={COLOR.WHITE} />,
  },
  {
    name: BulletPresetNames.Cross,
    bullet: <CrossIcon inline size="S" />,
  },
  {
    name: BulletPresetNames.Plus,
    bullet: <PlusIcon inline size="S" />,
  },
] as const

export default bulletPresets
