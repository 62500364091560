import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Wrapper, Image } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'

import TrustpilotSummary from 'components/TrustpilotWidget/TrustpilotSummary'

const HorizontalLayout = styled(Wrapper)`
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`

const LogosWrapper = styled(Wrapper)`
  & > * {
    margin-right: ${GTR.XS};
  }

  & img {
    height: 48px;
  }
`

const TrustPilotBanner = ({ showLabel, logos }) => (
  <HorizontalLayout centered noTrim>
    <Wrapper margin={['M', 'S', 0, 0]}>
      <TrustpilotSummary showLabel={showLabel} />
    </Wrapper>
    <LogosWrapper margin={['M', 0, 0, 0]}>
      {logos.map((path) => (
        <Image key={path} path={path} width={98} />
      ))}
    </LogosWrapper>
  </HorizontalLayout>
)

TrustPilotBanner.defaultProps = { showLabel: false, logos: [] }
TrustPilotBanner.propTypes = {
  showLabel: PropTypes.bool,
  logos: PropTypes.arrayOf(PropTypes.string),
}

export default TrustPilotBanner
