import React, { useState, createRef } from 'react'
import styled, { css } from 'styled-components'
import {
  BackgroundImageWrapper,
  H,
  P,
  Image,
  Wrapper,
  ClockIcon,
} from '@farewill/ui'
import { GTR, COLOR, BREAKPOINT, FONT } from '@farewill/ui/tokens'
import { screenMax, screenMin } from '@farewill/ui/helpers/responsive'

import { kebabCase } from 'lib/formatting/text'
import BreakpointSwitch from '../BreakpointSwitch'
import DropdownButton from '../DropdownButton'

const TRANSITION_DURATION_IN_MS = 200

const StyledCardWrapper = styled(Wrapper)`
  padding: ${GTR.M};
  width: 100%;

  ${screenMin.m`
    padding: ${GTR.M} ${GTR.M} ${GTR.L} ${GTR.M};
  `}
`

const StyledCardHeaderWrapper = styled.div`
  display: flex;

  ${screenMax.s`
    cursor: pointer;
  `}
`

const StyledCardHeaderLeft = styled.div`
  text-align: center;
  flex: 1 0 50%;
`
const StyledCardHeaderRight = styled.div`
  flex: 0 0 50%;
  text-align: center;
`

const StyledCardHeading = styled(H)`
  margin-bottom: 0;

  ${screenMin.m`
    margin-bottom: 14px;
  `}
`

const StyledCardSubheading = styled(H)`
  display: inline-flex;
  align-items: center;
  font-weight: ${FONT.WEIGHT.REGULAR};
  font-size: ${FONT.SIZE.S};
  line-height: 1.5;
  color: ${COLOR.BLACK};
`

const StyledIconClock = styled(ClockIcon)`
  margin-right: 11px;
`

const StyledNumberWrapper = styled(BackgroundImageWrapper)`
  width: 48px;
  height: 48px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: ${FONT.SIZE.L};
  font-weight: ${FONT.WEIGHT.BOLD};
  color: ${COLOR.BLACK};
  margin-right: 12px;
  user-select: none;

  ${screenMin.m`
    width: ${GTR.XXL};
    height: ${GTR.XXL};
    margin-right: 0;
    margin-bottom: ${GTR.M};
  `}
`

const StyledImage = styled(Image)`
  margin-top: -29px;
  margin-bottom: -27px;
  user-select: none;
`

const StyledDropdownButton = styled(DropdownButton)`
  flex: 1 0 auto;
  text-align: left;

  &:focus {
    outline: none;
  }
`

const StyledCardBodyWrapper = styled.div<{ $height: number }>`
  max-height: none;

  ${screenMax.s`
    max-height: 0;
    will-change: max-height;
    transition:  max-height ${TRANSITION_DURATION_IN_MS}ms ease-in-out;
    overflow: hidden;
    margin-top: 0;
  `}

  ${({ $height }) =>
    $height &&
    screenMax.s`
    max-height: ${$height}px
  `}
`

const StyledCardBodyInner = styled.div<{
  $isVisible: boolean
}>`
  display: flex;
  flex-wrap: wrap;
  padding-top: ${GTR.M};
  margin-top: ${GTR.M};
  border-top: 2px dotted #ffdf4e;

  ${screenMax.s`
  opacity: 0;
  transition: opacity ${TRANSITION_DURATION_IN_MS}ms ease-in-out;
  `}

  ${({ $isVisible }) => $isVisible && screenMax.s`opacity: 1;`}
`

const StyledCardBodyP = styled(P)<{
  $multiCol: boolean
}>`
  flex: 1 0 100%;
  color: ${COLOR.BLACK};

  &:last-child {
    margin-bottom: 0;
  }

  ${({ $multiCol }) =>
    $multiCol &&
    css`
      ${screenMin.m`
      flex: 1 0 50%;
      padding-left: ${GTR.S};

        &:nth-child(odd) {
          margin-left: -${GTR.S}
        }
      `};
    `}
`

interface ExpandingStepNumberedCardProps {
  description: string[]
  illustration: string
  subtitle: string
  title: string
  index: number
}

const ExpandingStepNumberedCard = ({
  description,
  illustration,
  subtitle,
  title,
  index,
}: ExpandingStepNumberedCardProps): React.ReactElement => {
  const [cardBodyVisibleOnMobile, setCardBodyVisibleOnMobile] =
    useState<boolean>(false)
  const [isTransitioning, setIsTransitioning] = useState<boolean>(false)
  const [cardBodyHeight, setCardBodyHeight] = useState<number>(0)
  const cardBodyEl = createRef<HTMLDivElement>()

  const showCardBody = () => {
    const ref = cardBodyEl.current
    const refHeight = ref?.offsetHeight || 0
    const refHeightMargin = ref
      ? parseInt(getComputedStyle(ref).marginTop, 10)
      : 0

    setIsTransitioning(true)
    setCardBodyHeight(refHeight + refHeightMargin)
    setCardBodyVisibleOnMobile(true)

    setTimeout(() => {
      setIsTransitioning(false)
    }, TRANSITION_DURATION_IN_MS)
  }

  const hideCardBody = () => {
    setIsTransitioning(true)
    setCardBodyHeight(0)
    setCardBodyVisibleOnMobile(false)

    setTimeout(() => {
      setIsTransitioning(false)
    }, TRANSITION_DURATION_IN_MS)
  }

  const onClickHandler = () => {
    if (window.innerWidth > BREAKPOINT.S || isTransitioning) return

    if (cardBodyVisibleOnMobile) hideCardBody()
    else showCardBody()
  }

  const DesktopCardHeader = (): React.ReactElement => (
    <>
      <StyledCardHeaderLeft>
        <StyledNumberWrapper
          imagePath="textures/blob-1-yellow"
          imageWidth={72}
          cover
        >
          {index + 1}
        </StyledNumberWrapper>
        <StyledCardHeading tag="h3" size="S">
          {title}
        </StyledCardHeading>
        <StyledCardSubheading tag="h4" size="XS" margin={0}>
          <StyledIconClock size="M" />
          {subtitle}
        </StyledCardSubheading>
      </StyledCardHeaderLeft>
      {illustration && (
        <StyledCardHeaderRight>
          <StyledImage
            path="illustrations/cactus-turtle-house"
            width={219}
            height={219}
          />
        </StyledCardHeaderRight>
      )}
    </>
  )

  const MobileCardHeader = (): React.ReactElement => (
    <>
      <StyledNumberWrapper
        imagePath="textures/blob-1-yellow"
        imageWidth={48}
        cover
      >
        {index + 1}
      </StyledNumberWrapper>
      <StyledDropdownButton
        onClick={() => null}
        isOpen={cardBodyVisibleOnMobile}
        ariaExpanded={cardBodyVisibleOnMobile}
        ariaControls={`step-${kebabCase(title)}`}
      >
        <StyledCardHeading tag="h3" size="S">
          {title}
        </StyledCardHeading>
        <StyledCardSubheading tag="h4" size="XS" margin={0}>
          {subtitle}
        </StyledCardSubheading>
      </StyledDropdownButton>
    </>
  )

  return (
    <StyledCardWrapper
      container
      background="#fff9dc"
      borderRadius="S"
      containerPaddingTop="M"
      containerPaddingBottom="M"
      onClick={onClickHandler}
    >
      <StyledCardHeaderWrapper>
        <BreakpointSwitch
          aboveComponent={<DesktopCardHeader />}
          belowComponent={<MobileCardHeader />}
          breakAt={BREAKPOINT.S}
        />
      </StyledCardHeaderWrapper>
      <StyledCardBodyWrapper $height={cardBodyHeight}>
        <StyledCardBodyInner
          ref={cardBodyEl}
          id={`step-${kebabCase(title)}`}
          $isVisible={cardBodyVisibleOnMobile}
        >
          {description.map((descriptionItem) => (
            <StyledCardBodyP
              key={`para-${kebabCase(descriptionItem)}`}
              $multiCol={!!illustration}
            >
              {descriptionItem}
            </StyledCardBodyP>
          ))}
        </StyledCardBodyInner>
      </StyledCardBodyWrapper>
    </StyledCardWrapper>
  )
}

export default ExpandingStepNumberedCard
