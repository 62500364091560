import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Wrapper, H, Divider } from '@farewill/ui'
import { COLOR, FONT } from '@farewill/ui/tokens'
import { GTR } from '@farewill/ui/tokens/layout'

import { formatPriceInPounds } from 'lib/formatting/pricing'

import CardWrapper from 'components/CardWrapper'

const StyledCardTitle = styled(Wrapper)`
  text-transform: uppercase;
  font-size: ${FONT.SIZE.XS};
  font-weight: ${FONT.WEIGHT.BOLD};
  letter-spacing: 1.2px;
  margin-bottom: ${GTR.XS};

  ${({ $emphasized }) =>
    $emphasized &&
    `
    color: ${COLOR.BLACK};
    font-size: ${FONT.SIZE.L};
    text-transform: none;
    letter-spacing: 0;
    margin-bottom: 0;
  `}
`

const StyledPriceInfo = styled.span`
  color: ${COLOR.GREY.DARK};
  margin-left: ${GTR.XS};
  white-space: nowrap;
`

const StyledDescription = styled.p`
  font-size: ${FONT.SIZE.S};
  color: ${COLOR.GREY.DARK};
  border-top: 1px solid ${COLOR.GREY.LIGHT};
  margin-top: ${GTR.S};
  padding-top: ${GTR.S};
`

const formatPrice = (price) => {
  if (!price) return null
  if (typeof price === 'string') return price
  return formatPriceInPounds(price)
}

const Card = ({
  price,
  title,
  header,
  highlight,
  children,
  priceInfo,
  emphasized,
  centered,
  description,
}) => (
  <CardWrapper highlight={highlight} header={header} centered={centered}>
    <StyledCardTitle $emphasized={emphasized}>{title}</StyledCardTitle>
    <H decorative size="L" tag="h5" margin={0} style={{ display: 'inline' }}>
      {formatPrice(price)}
    </H>
    {!!priceInfo && <StyledPriceInfo>{priceInfo}</StyledPriceInfo>}

    {children && (
      <>
        <Divider margin={['S', 0]} />
        {children}
      </>
    )}
    {!!description && <StyledDescription>{description}</StyledDescription>}
  </CardWrapper>
)

Card.propTypes = {
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  title: PropTypes.string,
  header: PropTypes.string,
  highlight: PropTypes.bool,
  children: PropTypes.node,
  priceInfo: PropTypes.string,
  emphasized: PropTypes.bool,
  centered: PropTypes.bool,
  description: PropTypes.string,
}

Card.defaultProps = {
  title: '',
  header: '',
  highlight: false,
  children: undefined,
  priceInfo: 'inc VAT',
  emphasized: false,
  centered: false,
  description: '',
}

export default Card
