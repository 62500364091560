import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Wrapper, Grid, P, Image } from '@farewill/ui'
import { COLOR, BORDER, GTR } from '@farewill/ui/tokens'
import { screenMin } from '@farewill/ui/helpers/responsive'
import { headingM } from '@farewill/ui/helpers/text'
import PreventOrphan from 'components/PreventOrphan'
import SimpleQuote from 'components/SimpleQuote'
import { AWARD_LOGO_URL } from 'lib/awards/constants'

import { QUOTES } from './constants'

export { QUOTE_ID as CUSTOMER_QUOTE_ID } from './constants'

const AVATAR_SIZE = 36
const AVATAR_SIZE_FROM_M = 54

const StyledCustomerQuote = styled(Grid)`
  /*
* min-height is used to ensure that irrespective of the length of the quote text,
* the photo never gets cropped.
*/
  min-height: 364px;
  overflow: hidden;
`

const StyledAvatar = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: ${AVATAR_SIZE}px;
  height: ${AVATAR_SIZE}px;
  margin-right: ${GTR.XS};
  border-radius: 50%;
  overflow: hidden;

  ${screenMin.m`
    width: ${AVATAR_SIZE_FROM_M}px;
    height: ${AVATAR_SIZE_FROM_M}px;
    margin-right: ${GTR.S};
  `}

  ${screenMin.l`
    display: none;
  `}
`

const StyledAttribution = styled(P)`
  display: inline-block;
`

const StyledPhotoFrameGridItem = styled(Grid.Item)`
  display: none;

  && {
    margin-bottom: -${GTR.L};
  }

  ${screenMin.l`
    display: block;
  `}
`

const StyledPhotoFrame = styled(Image)`
  border: solid ${COLOR.WHITE} 10px;
  background-color: ${COLOR.WHITE};
  position: relative;
  top: 27px;
  margin-top: -${GTR.L};
  box-shadow: ${BORDER.SHADOW.M};
  ${({ $imageOnLeft }) => `transform: rotate(${$imageOnLeft ? '-' : ''}10deg)`}
`

const StyledSimpleQuote = styled(SimpleQuote)`
  ${headingM}
`
const StyledTrustpilotLogo = styled(Image)`
  margin-right: ${GTR.XS};
`

const CustomerQuote = ({
  className,
  id,
  imageOnLeft,
  slim,
  fullWidth,
  background,
  showTrustpilot,
}) => {
  const testimonial = QUOTES[id]

  if (!testimonial) {
    console.error(`No customer quote found with id '${testimonial}'`)
    return null
  }

  const { quote, attribution, imagePath } = testimonial

  return (
    <Wrapper background={fullWidth ? background : null}>
      <StyledCustomerQuote
        className={className}
        container
        containerPaddingTop="L"
        containerPaddingBottom="L"
        paddingFromXL={['XXL', 'XL']}
        gap={0}
        gapFromL="L"
        maxWidth={slim ? 1050 : null}
        background={background}
        borderRadius="S"
      >
        <Grid.Item
          spanFromL={8}
          spanFromXL={9}
          startColumnFromL={imageOnLeft ? 5 : 1}
          startColumnFromXL={imageOnLeft ? 4 : 1}
        >
          <StyledSimpleQuote decorative margin={[0, 0, 'M']}>
            <PreventOrphan>{quote}</PreventOrphan>
          </StyledSimpleQuote>
          <StyledAvatar>
            <Image
              path={imagePath}
              width={AVATAR_SIZE}
              widthFromM={AVATAR_SIZE_FROM_M}
              ext="jpg"
            />
          </StyledAvatar>
          {showTrustpilot && (
            <StyledTrustpilotLogo
              path={AWARD_LOGO_URL.TRUSTPILOT_FIVE_STARS}
              width={112}
              alt="Five stars from Trustpilot"
            />
          )}
          <StyledAttribution>{attribution}</StyledAttribution>
        </Grid.Item>

        <StyledPhotoFrameGridItem
          centered
          span={0}
          spanFromL={4}
          spanFromXL={3}
        >
          <StyledPhotoFrame
            path={imagePath}
            $imageOnLeft={imageOnLeft}
            width={150}
            widthFromXL={180}
            stretch
            ext="jpg"
          />
        </StyledPhotoFrameGridItem>
      </StyledCustomerQuote>
    </Wrapper>
  )
}

CustomerQuote.propTypes = {
  className: PropTypes.string,
  id: PropTypes.oneOf(Object.keys(QUOTES)).isRequired,
  imageOnLeft: PropTypes.bool,
  slim: PropTypes.bool,
  fullWidth: PropTypes.bool,
  background: PropTypes.string,
  showTrustpilot: PropTypes.bool,
}

CustomerQuote.defaultProps = {
  className: null,
  imageOnLeft: false,
  slim: false,
  fullWidth: false,
  background: COLOR.STATE.WARNING_10,
  showTrustpilot: false,
}

export default CustomerQuote
