import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Grid, Image } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'
import { screenMax } from '@farewill/ui/helpers/responsive'

import WrapperWithEdge from 'components/WrapperWithEdge'

const SyledWrapperWithEdge = styled(WrapperWithEdge)`
  overflow: hidden;
`

const StyledTextureSquiggle = styled(Image)`
  position: absolute;
  pointer-events: none;
  z-index: -1;

  top: -11%;
  right: -10%;

  ${screenMax.m`
    display: none
  `};
`

const StyledTextureSwoosh = styled(Image)`
  position: absolute;
  pointer-events: none;
  z-index: -1;

  bottom: -15%;
  right: 10%;

  ${screenMax.m`
    display: none
  `};
`

const StyledTextureDetail = styled(Image)`
  position: absolute;
  pointer-events: none;
  z-index: -1;

  bottom: 0;
  right: -5%;

  ${screenMax.m`
    display: none
  `};
`

const StyledGrid = styled(Grid)`
  align-items: center;
  grid-row-gap: ${GTR.L};
`

const WrapperWithTextures = ({ children }) => (
  <SyledWrapperWithEdge padding={[0, 0, 'XS']} direction="down">
    <StyledTextureSquiggle path="textures/squiggly_01a" width={600} />
    <StyledTextureSwoosh path="textures/swoosh_yellow" width={400} />
    <StyledTextureDetail path="textures/detail_new_yellow02" width={250} />
    <StyledGrid container>{children}</StyledGrid>
  </SyledWrapperWithEdge>
)

WrapperWithTextures.propTypes = {
  children: PropTypes.node.isRequired,
}

export default WrapperWithTextures
