import React, { ReactNode } from 'react'
import { Wrapper, Grid, H, P } from '@farewill/ui'

import PreventOrphan from 'components/PreventOrphan'

export const HERO_VARIANTS = {
  RIGHT: 'right',
  CENTERED: 'centered',
} as const

export type HeroProps = {
  title: string | ReactNode
  titleMaxWidth?: string
  subtitle?: string
  additionalContent?: ReactNode
  additionalGridItems?: ReactNode | ReactNode[]
  variant?: (typeof HERO_VARIANTS)[keyof typeof HERO_VARIANTS]
}

const Hero = ({
  title,
  titleMaxWidth = '460px',
  subtitle,
  additionalContent,
  additionalGridItems,
  variant = HERO_VARIANTS.RIGHT,
}: HeroProps): React.ReactElement => {
  const isCenteredVariant = variant === HERO_VARIANTS.CENTERED
  return (
    <Grid
      container
      style={{ alignItems: 'center' }}
      paddingFromXL={isCenteredVariant ? ['S', 0, 'XXL'] : undefined}
      marginFromXL={isCenteredVariant ? [0, 'auto', 'L'] : undefined}
      gapFromL={isCenteredVariant ? 'S' : undefined}
    >
      <Grid.Item
        spanFromM={isCenteredVariant ? 12 : 6}
        spanFromL={isCenteredVariant ? 10 : undefined}
        spanFromXL={isCenteredVariant ? 8 : undefined}
        startColumnFromL={isCenteredVariant ? 2 : 1}
        startColumnFromXL={isCenteredVariant ? 3 : undefined}
        centeredFromM={isCenteredVariant}
      >
        <H style={{ maxWidth: titleMaxWidth }} size="XL" decorative>
          {title}
        </H>
        {subtitle && (
          <P margin={[0, 0, additionalContent ? 'M' : 0]} size="L">
            <PreventOrphan>{subtitle}</PreventOrphan>
          </P>
        )}
        <Wrapper>{additionalContent}</Wrapper>
      </Grid.Item>
      {additionalGridItems}
    </Grid>
  )
}

export default Hero
