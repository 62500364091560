import React from 'react'
import styled from 'styled-components'
import { Wrapper, Grid, H, P } from '@farewill/ui'

import { kebabCase } from 'lib/formatting/text'
import ExpandingStepNumbered from 'components/ExpandingStepNumbered'

const data = [
  {
    illustration: '',
    title: 'Value the estate',
    subtitle: '1 week to 2 months',
    description: [
      'Arrange property valuation',
      'Track down assets and debts',
      'Contact banks and other organisations to find out the value of the estate',
    ],
  },
  {
    illustration: '',
    title: 'Get probate',
    subtitle: '1 to 3 months',
    description: [
      'Work out tax',
      'Prepare probate and tax forms',
      'Submit application and get probate from the probate registry*',
    ],
  },
  {
    illustration: 'illustrations/cactus-turtle-house',
    title: 'Sort the estate ',
    subtitle: '3 to 6 months',
    description: [
      'Pay taxes and debts owed',
      'Contact pension providers',
      'Claim on life insurance policy',
      'Close bank accounts',
      'Pay money to beneficiaries',
      'Gather assets into one account',
      'Organise property sale or transfer',
      'Arrange sale or transfer of stocks and shares',
    ],
  },
]

const StyledGridItem = styled(Grid.Item)`
  display: flex;
  flex-direction: column;

  & > * {
    flex-grow: 1;
  }
`

const StyledP = styled(P)`
  max-width: 675px;
`

const HowItWorks = (): React.ReactElement => (
  <>
    <Wrapper containerPaddingBottom={100}>
      <H tag="h2" size="M" decorative centered margin={[0, 'auto', 'S']}>
        How Complete Probate works
      </H>
      <StyledP centered margin={[0, 'auto']}>
        Our legal team will sort out someone’s estate and affairs after they
        die, so you can get back to what matters most. The whole process takes
        between 3 to 18 months (or sometimes longer if the estate is more
        complex).{' '}
      </StyledP>
    </Wrapper>
    <Grid margin={['XL', 0, 'L', 0]}>
      {data.map(({ description, illustration, subtitle, title }, index) => (
        <StyledGridItem
          key={`step-${kebabCase(title)}`}
          spanFromM={illustration ? 12 : 6}
          spanFromXL={illustration ? 6 : 3}
        >
          <ExpandingStepNumbered
            title={title}
            subtitle={subtitle}
            description={description}
            illustration={illustration}
            index={index}
          />
        </StyledGridItem>
      ))}
    </Grid>
    <Wrapper>
      <P size="S" centered>
        *According to the probate registry, the current processing time is
        around 8 to 12 weeks (due to a 4 to 6 week delay).
      </P>
    </Wrapper>
  </>
)

export default HowItWorks
