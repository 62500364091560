export const QUOTE_ID = {
  TAMAR: 'tamar-cremation',
  NIKKI: 'nikki',
  CAROL: 'carol',
  TERRY: 'terry-probate',
  LORRAINE: 'lorraine-probate',
  A_PATTINSON: 'a-pattinson-probate',
  LOUISA: 'louisa-probate',
  MS_PEART: 'ms-peart-probate',
  ROB: 'rob-probate',
  JASON: 'jason-wills',
  SARAH: 'sarah-cremation',
  SARAH_CREMATION_LANDING: 'sarah-cremation-landing',
  ABI_CREMATION: 'abi-cremation',
  FRANKIE_CREMATION: 'frankie-cremation',
  TERRY_CREMATION: 'terry-cremation',
}

export const QUOTES = {
  [QUOTE_ID.TAMAR]: {
    quote:
      'Losing a parent does not make for good times but Farewill took the stress out of the cremation. I knew from the first call I was not going to have to worry.',
    attribution: 'Tamar, London',
    imagePath: 'customer-quote-portraits/tamar',
  },
  [QUOTE_ID.NIKKI]: {
    quote:
      'I never knew there were companies out there as professional and caring during our sad times as Farewill. The whole process from start to finish was flawless.',
    attribution: 'Nikki, Barnsley',
    imagePath: 'customer-quote-portraits/nikki',
  },
  [QUOTE_ID.CAROL]: {
    quote:
      'Massive thank you to all your staff for supporting and helping me at such a tragic time in my life.',
    attribution: 'Carol, Farewill Customer',
    imagePath: 'customer-quote-portraits/carol',
  },
  [QUOTE_ID.TERRY]: {
    quote:
      'I would highly recommend this company to all. I was looking to apply for probate for my late mother’s estate. This company were so efficient. Very, very friendly and professional.',
    attribution: 'Terry, probate customer',
    imagePath: 'customer-quote-portraits/terry',
  },
  [QUOTE_ID.LORRAINE]: {
    quote:
      'I can not recommend this company enough, they really helped through all the processes of probate with complete professionalism and sensitivity. Thank you so much for everything.',
    attribution: 'Lorraine, probate customer',
    imagePath: 'customer-quote-portraits/lorraine',
  },
  [QUOTE_ID.A_PATTINSON]: {
    quote:
      'I used Farewill to obtain probate for my mother’s estate and I have no regrets. Their communication with me was outstanding and the grant was obtained very swiftly. In addition, the cost was way lower than my solicitor quoted me!',
    attribution: 'A. Pattinson, probate customer',
    imagePath: 'customer-quote-portraits/a-pattinson',
  },
  [QUOTE_ID.LOUISA]: {
    quote:
      'Made the whole probate process so easy for me. Fast, friendly staff who kept me informed all the way from start to finish. Would recommend to anyone looking to get probate.',
    attribution: 'Louisa, probate customer',
    imagePath: 'customer-quote-portraits/louisa',
  },
  [QUOTE_ID.MS_PEART]: {
    quote:
      'Farewill restored my faith in human nature, honesty and trust after hitting a brick wall time and time again.',
    attribution: 'Ms Peart, probate customer',
    imagePath: 'customer-quote-portraits/ms-peart',
  },
  [QUOTE_ID.ROB]: {
    quote:
      'They’re superb communicators and are very sensitive at such a difficult time in your life.',
    attribution: 'Rob, probate customer',
    imagePath: 'customer-quote-portraits/rob',
  },
  [QUOTE_ID.JASON]: {
    quote:
      'It’s refreshing to find a will writing service that takes out all the complexity and jargon. A simple and cost effective way of ensuring my loved ones will be okay if anything happens to me.',
    attribution: 'Jason Peachell',
    imagePath: 'customer-quote-portraits/j-peachell',
  },
  [QUOTE_ID.SARAH]: {
    quote:
      'Given the exorbitant costs of funerals these days, Farewill provided a service that was more than a fair price.',
    attribution: 'Sarah, cremation customer',
    imagePath: 'customer-quote-portraits/sarah',
  },
  [QUOTE_ID.SARAH_CREMATION_LANDING]: {
    quote:
      'The stress was taken out of the whole process, they were caring and professional. We knew in advance when the cremation would take place so organised a celebration of life dinner.',
    attribution: 'Sarah, cremation customer',
    imagePath: 'customer-quote-portraits/sarah',
  },
  [QUOTE_ID.ABI_CREMATION]: {
    quote:
      'My grandmother wanted a simple send off with her ashes scattering at her chosen location and only the closest family and friends present. Farewill offered us the perfect way to carry out her wishes.',
    attribution: 'Abi, funeral customer',
    imagePath: 'customer-quote-portraits/sarah',
  },
  [QUOTE_ID.FRANKIE_CREMATION]: {
    quote:
      'The service was excellent and you would not get better by going to a high street funeral director and paying 2 or 3 times as much. We couldn’t have asked for a better send off for our brother.',
    attribution: 'Frankie, funeral customer',
    imagePath: 'customer-quote-portraits/frankie',
  },
  [QUOTE_ID.TERRY_CREMATION]: {
    quote:
      'The service was excellent and you would not get better by going to a high street funeral director and paying 2 or 3 times as much. We couldn’t have asked for a better send off for our brother.',
    attribution: 'Terry, funeral customer',
    imagePath: 'customer-quote-portraits/terry',
  },
}
