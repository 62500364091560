import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Wrapper } from '@farewill/ui'
import { COLOR, BORDER_RADIUS, FONT } from '@farewill/ui/tokens'
import { GTR } from '@farewill/ui/tokens/layout'
import { screen } from '@farewill/ui/helpers/responsive'

const accentColorFromHighlight = (highlight) =>
  highlight ? COLOR.ACCENT.PRIMARY : COLOR.GREY.LIGHT

const StyledCardWrapper = styled.nav`
  background: ${COLOR.WHITE};
  box-shadow: inset 0 0 0 2px
    ${({ $highlight }) => accentColorFromHighlight($highlight)};
  border-radius: ${BORDER_RADIUS.M};
  font-size: ${({ $hasLargeBodyText }) =>
    $hasLargeBodyText ? `${FONT.SIZE.M};` : `${FONT.SIZE.S};`};
  text-align: ${({ $centered }) => ($centered ? `center;` : `left;`)};
  max-width: 600px;

  ${({ $highlight }) =>
    $highlight && `--card-accent-color: ${COLOR.ACCENT.PRIMARY};`}
`

const StyledCardWrapperHeader = styled.span`
  display: block;
  min-height: 1.5em;
  border-top-left-radius: ${BORDER_RADIUS.M};
  border-top-right-radius: ${BORDER_RADIUS.M};
  background-color: ${({ $highlight }) => accentColorFromHighlight($highlight)};
  padding: ${({ $hasWideHeader }) =>
    $hasWideHeader ? `${GTR.S};` : `${GTR.XXS} ${GTR.S};`};
  ${({ $hasBoldHeadingText }) =>
    $hasBoldHeadingText && `font-weight: ${FONT.WEIGHT.BOLD};`};

  ${({ $hasLargeHeaderText }) =>
    $hasLargeHeaderText && `font-size: ${FONT.SIZE.L};`};

  ${screen.m`
    font-size: ${FONT.SIZE.M}
  `};

  color: ${COLOR.BLACK};
`

const CardWrapper = ({
  className,
  header,
  highlight,
  children,
  hasBoldHeadingText,
  hasWideHeader,
  hasLargeHeaderText,
  hasLargeBodyText,
  centered,
}) => (
  <StyledCardWrapper
    className={className}
    $centered={centered}
    $highlight={highlight}
    $hasWideHeader={hasWideHeader}
    $hasLargeBodyText={hasLargeBodyText}
  >
    <StyledCardWrapperHeader
      className="card-header"
      $hasBoldHeadingText={hasBoldHeadingText}
      $hasLargeHeaderText={hasLargeHeaderText}
      $highlight={highlight}
      $hasWideHeader={hasWideHeader}
      $hasLargeBodyText={hasLargeBodyText}
    >
      {header}
    </StyledCardWrapperHeader>
    <Wrapper padding="S">{children}</Wrapper>
  </StyledCardWrapper>
)

CardWrapper.propTypes = {
  className: PropTypes.string,
  header: PropTypes.string,
  highlight: PropTypes.bool,
  hasBoldHeadingText: PropTypes.bool,
  hasLargeHeaderText: PropTypes.bool,
  hasLargeBodyText: PropTypes.bool,
  centered: PropTypes.bool,
  hasWideHeader: PropTypes.bool,
  children: PropTypes.node,
}

CardWrapper.defaultProps = {
  className: '',
  header: '',
  highlight: false,
  hasBoldHeadingText: false,
  hasLargeHeaderText: false,
  hasLargeBodyText: false,
  centered: false,
  hasWideHeader: false,
  children: undefined,
}

export default CardWrapper
