import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Wrapper, Image } from '@farewill/ui'
import { FONT, COLOR, BORDER, GTR } from '@farewill/ui/tokens'
import { screenMin } from '@farewill/ui/helpers/responsive'
import { headingM, smallCaps } from '@farewill/ui/helpers/text'

import PreventOrphan from 'components/PreventOrphan'

import { QUOTES } from './constants'
import SimpleQuote from '../SimpleQuote'

const AVATAR_SIZE_INLINE = 36
const AVATAR_SIZE = 60
const AVATAR_SIZE_FROM_M = 116

const StyledVerticalQuote = styled(Wrapper)`
  box-shadow: ${BORDER.SHADOW.M};
`

const StyledAvatar = styled.div`
  display: none;
  vertical-align: middle;
  width: ${AVATAR_SIZE}px;
  height: ${AVATAR_SIZE}px;
  margin-bottom: ${GTR.S};
  border-radius: 50%;
  overflow: hidden;

  ${screenMin.m`
    display: inline-block;
    width: ${AVATAR_SIZE_FROM_M}px;
    height: ${AVATAR_SIZE_FROM_M}px;
    margin-bottom: ${GTR.M};
  `}

  ${screenMin.l`
    margin-bottom: ${GTR.XL};
  `}
`

const StyledAvatarInline = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: ${AVATAR_SIZE_INLINE}px;
  height: ${AVATAR_SIZE_INLINE}px;
  margin-right: ${GTR.S};
  border-radius: 50%;
  overflow: hidden;

  ${screenMin.m`
    display: none;
  `}
`

const StyledAttribution = styled.p`
  display: inline-block;
  color: ${COLOR.GREY.DARK};
  margin: ${GTR.S} 0 0;
  ${smallCaps};
`

const StyledSimpleQuote = styled(SimpleQuote)`
  ${headingM}
`

const StyledBlockquote = styled.blockquote`
  font-size: ${FONT.SIZE.M};
  margin: 0 auto;

  &:before {
    content: '“';
  }

  &:after {
    content: '”';
  }

  ${screenMin.m`
    font-size: ${FONT.SIZE.L}
  `}
`

const VerticalQuote = ({ className, id, decorative }) => {
  const testimonial = QUOTES[id]

  if (!testimonial) {
    console.error(`No customer quote found with id '${testimonial}'`)
    return null
  }

  const { quote, attribution, imagePath } = testimonial

  return (
    <StyledVerticalQuote
      className={className}
      borderRadius="S"
      background={COLOR.STATE.WARNING_10}
      padding="S"
      paddingFromM="M"
      paddingFromL="L"
      centered
    >
      <StyledAvatar>
        <Image
          path={imagePath}
          width={AVATAR_SIZE}
          widthFromM={AVATAR_SIZE_FROM_M}
        />
      </StyledAvatar>

      {decorative ? (
        <StyledSimpleQuote decorative centered>
          <PreventOrphan>{quote}</PreventOrphan>
        </StyledSimpleQuote>
      ) : (
        <StyledBlockquote>
          <PreventOrphan>{quote}</PreventOrphan>
        </StyledBlockquote>
      )}

      <StyledAvatarInline>
        <Image path={imagePath} width={AVATAR_SIZE_INLINE} />
      </StyledAvatarInline>
      <StyledAttribution>{attribution}</StyledAttribution>
    </StyledVerticalQuote>
  )
}

VerticalQuote.propTypes = {
  className: PropTypes.string,
  id: PropTypes.oneOf(Object.keys(QUOTES)).isRequired,
  decorative: PropTypes.bool,
}

VerticalQuote.defaultProps = {
  className: null,
  decorative: true,
}

export default VerticalQuote
