import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Wrapper } from '@farewill/ui'
import { screenMin } from '@farewill/ui/helpers/responsive'
import { FONT } from '@farewill/ui/tokens'

const baseBeforeStyles = `
  position: absolute;
  left: -1ch;
`

const StyledSimpleQuote = styled((props) => (
  <Wrapper
    omitProps={{
      keys: ['color', 'centeredUntil', 'decorative'],
    }}
    tag="blockquote"
    bumpSpecificity
    {...props} //eslint-disable-line
  />
))`
  position: relative;
  margin: auto;

  ${({ decorative }) =>
    decorative &&
    `
    font-family: ${FONT.FAMILY.DECORATIVE};
    font-weight: ${FONT.WEIGHT.REGULAR};
  `}

  ${({ color }) => color && `color: ${color};`}

  &:before {
    content: '“';

    ${(props) => {
      if (!props.centered && !props.centeredUntil) return baseBeforeStyles
      return props.centeredUntil
        ? screenMin[props.centeredUntil.toLowerCase()]`${baseBeforeStyles}`
        : null
    }}
  }

  &:after {
    content: '”';
  }
`

const SimpleQuote = ({ children, ...props }) => (
  <StyledSimpleQuote {...props}>{children}</StyledSimpleQuote> //eslint-disable-line
)

SimpleQuote.propTypes = {
  children: PropTypes.node.isRequired,
  /** when quote is centered, front dash shouldn't be absolutely positioned */
  /** this property defines until which breakpoint is text centered
   *  (as text centering is more common on mobile screens)
   */
  centeredUntil: PropTypes.oneOf(['S', 'M', 'L', 'XL', false]),
  decorative: PropTypes.bool,
}

SimpleQuote.defaultProps = {
  centeredUntil: false,
  decorative: false,
}

export default SimpleQuote
