import { PROBATE_ESTATE_ADMIN_FEE } from 'config'
import { formatPriceInPounds } from 'lib/formatting/pricing'

export const ESTATE_ADMIN_PRICE = {
  price: `From ${formatPriceInPounds(PROBATE_ESTATE_ADMIN_FEE)}`,
  title: 'Complete Probate',
  included: [
    'Less than 8 bank accounts, investments and life policies',
    'No trusts or disputes with the will',
    'If there is no will, the estate is passing directly to a spouse or direct descendents',
    'Less than 8 beneficaries',
  ],
  notIncluded: [
    'Third-party fees like a stock broker, or legal fees to sell a property',
  ],
}

export const FEATURES = [
  {
    illustration: 'couples-thumbs-up',
    name: 'Get a dedicated specialist ',
    description:
      "We'll take care of the legal paperwork and take responsibility for sorting the whole estate.",
  },
  {
    illustration: 'phone-ringing',
    name: 'Save lots of time',
    description:
      "We'll contact banks, arrange to sell shares or property, and coordinate beneficiaries on your behalf.",
  },
  {
    illustration: 'experts',
    name: 'Sort the admin on your behalf',
    description:
      "We'll collect the money due to the estate, pay any debts, and make sure everyone’s paid correctly.",
  },
]
