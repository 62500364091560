import React from 'react'
import styled from 'styled-components'

import { Image, P, Wrapper, TickIcon } from '@farewill/ui'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'
import { screenMin } from '@farewill/ui/helpers/responsive'

import HeroWithImage from 'components/HeroWithImage'
import TrustpilotSummary from 'components/TrustpilotWidget/TrustpilotSummary'
import BulletedList from 'components/BulletedList'
import QuoteButton from 'views/probate/components/QuoteButton'
import { formatPriceInPounds } from 'lib/formatting/pricing'
import { FLOW_PROBATE_URL, PROBATE_ESTATE_ADMIN_FEE } from 'config'

const CHECKLIST_ITEMS = [
  'Hand over complex tasks and save lots of time',
  'Give us the responsibility of the legal work',
  'Let us manage communication on your behalf',
]

const StyledDescription = styled.div`
  ${screenMin.m`
    font-size: ${FONT.SIZE.L};
  `}
`

const StyledWrapper = styled(Wrapper)`
  margin: 0 0 ${GTR.M};
`

const Hero = () => (
  <HeroWithImage
    title="Sort the estate from start to finish, with Complete Probate"
    titleMaxWidth="none"
    additionalContent={
      <>
        <StyledDescription
          as={BulletedList}
          items={CHECKLIST_ITEMS}
          bullet={<TickIcon size="M" />}
          bulletColor={COLOR.BLACK}
        />
        <br />
        <StyledWrapper>
          <P size="L" strong>
            Clear, fixed quote from{' '}
            {formatPriceInPounds(PROBATE_ESTATE_ADMIN_FEE)}
          </P>
          <QuoteButton
            buttonText="Get an instant quote"
            boldText
            showArrow={false}
            linkPath={FLOW_PROBATE_URL}
          />
        </StyledWrapper>
        <TrustpilotSummary />
      </>
    }
    image={
      <Image
        path="illustrations/cropped/key-unlocking-assets"
        width={200}
        widthFromM={350}
        widthFromL={500}
        stretch
      />
    }
  />
)

export default Hero
