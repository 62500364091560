import React from 'react'
import styled from 'styled-components'
import { Grid, H, Image, Wrapper } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'
import { screenMax } from '@farewill/ui/helpers/responsive'

import WrapperWithEdge from 'components/WrapperWithEdge'
import VerticalQuote from 'components/CustomerQuote/VerticalQuote'
import { CUSTOMER_QUOTE_ID } from 'components/CustomerQuote'
import TrustpilotSummary from 'components/TrustpilotWidget/TrustpilotSummary'

const StyledTextureLeft = styled(Image)`
  position: absolute;
  pointer-events: none;
  z-index: -1;
  max-height: 90%;
  left: 5%;
  top: 5%;

  ${screenMax.m`
    display: none;
  `};
`

const StyledTextureBottom = styled(Image)`
  position: absolute;
  pointer-events: none;
  z-index: -1;
  transform: rotate(10deg);
  max-height: 90%;
  left: 35%;
  bottom: -5%;

  ${screenMax.m`
    display: none;
  `};
`

const StyledTextureRight = styled(Image)`
  position: absolute;
  pointer-events: none;
  z-index: -1;
  max-height: 60%;
  top: 15%;
  right: 5%;

  ${screenMax.m`
    display: none;
  `};
`

const StyledGridItem = styled(Grid.Item)`
  display: flex;
`

const Quotes = () => (
  <WrapperWithEdge direction="down" edgeBackgroundColor={COLOR.BACKGROUND.FOG}>
    <StyledTextureLeft path="textures/squiggly_01a" width={500} />
    <StyledTextureBottom path="textures/swoosh_yellow" width={350} />
    <StyledTextureRight path="textures/detail_new_yellow02" width={400} />
    <Wrapper container centeredFromM>
      <H size="L" as="h2" decorative>
        Helping families deal with life’s toughest moments
      </H>
      <Grid gapFromL="XL">
        <StyledGridItem spanFromL={5} startColumnFromL={2}>
          <VerticalQuote id={CUSTOMER_QUOTE_ID.MS_PEART} />
        </StyledGridItem>
        <StyledGridItem spanFromL={5}>
          <VerticalQuote id={CUSTOMER_QUOTE_ID.ROB} />
        </StyledGridItem>
        <Grid.Item centered>
          <TrustpilotSummary />
        </Grid.Item>
      </Grid>
    </Wrapper>
  </WrapperWithEdge>
)

export default Quotes
